import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../../assets/logo.png'
import Logow from '../../assets/marshall_logo.png';
import './navbar.css'
import { ImSearch } from "react-icons/im";
import { TbHelpOctagon } from "react-icons/tb";
import {AiOutlineShoppingCart} from "react-icons/ai";
import { FaBars } from "react-icons/fa";
import { useEffect, useState } from 'react';

function NavComponent() {
  const [screenSize, setScreenSize] = useState('');

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      if (width < 600) {
        setScreenSize('small');
      } else {
        setScreenSize('medium');
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Navbar collapseOnSelect expand="lg" variant="dark">
      <Container>
        <Navbar.Brand href="#home">
          <img src={Logow} alt="" />
        </Navbar.Brand>
        {screenSize == 'small' &&(
          <>
            <div className='navbar_icons__container'>
              <Nav className='d-flex flex-row nav_icons'>
                <Nav.Link href="#features" className='mx-2'>
                  <ImSearch size={30} color="white"/>
                </Nav.Link>
                <Nav.Link href="#features" className='mx-2'>
                  <TbHelpOctagon size={30} color="white"/>
                </Nav.Link>
                <Nav.Link href="#features" className='mx-2'>
                  <AiOutlineShoppingCart size={30} color="white"/>
                </Nav.Link>
              </Nav>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" className='btn_nav'/>
            </div>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="nav_itens">
                  <Nav.Link href="#features">INICIO</Nav.Link>
                  <Nav.Link href="#pricing">AURICULARES</Nav.Link>
                  <Nav.Link href="#pricing">ALTAVOCES</Nav.Link>
                  <Nav.Link href="#pricing">DIAMOND JUBILEE EDITION</Nav.Link>
                  <Nav.Link href="#pricing">NEVER STOP LISTENING</Nav.Link>
                  <Nav.Link href="#pricing">INICIAR SESION</Nav.Link>
                </Nav>
            </Navbar.Collapse>
          </>
        )}
        {screenSize == 'medium' &&(
          <>
            <Navbar.Collapse id="responsive-navbar-nav" style={{justifyContent:'center'}}>
            <Nav className="nav_itens">
              <Nav.Link href="#pricing">AURICULARES</Nav.Link>
              <Nav.Link href="#pricing">ALTAVOCES</Nav.Link>
              <Nav.Link href="#pricing">DIAMOND JUBILEE EDITION</Nav.Link>
              <Nav.Link href="#pricing">NEVER STOP LISTENING</Nav.Link>
            </Nav>
            </Navbar.Collapse>
            <div className='link_nav__container'>
              <Nav className='d-flex flex-row nav_icons'>
                <Nav.Link href="#features" className='mx-2'>
                  <ImSearch size={20} color="white"/>
                </Nav.Link>
                <Nav.Link href="#features" className='mx-2'>
                  <TbHelpOctagon size={20} color="white"/>
                </Nav.Link>
                <Nav.Link href="#features" className='mx-2'>
                  <AiOutlineShoppingCart size={20} color="white"/>
                </Nav.Link>
              </Nav>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" className='btn_nav'/>
            </div>
          </>
        )}
      </Container>
    </Navbar>
  );
}

export default NavComponent;