import Navbar from "./navbar"

function Header() {
  return(
    <section className="Header">
      <Navbar/>
    </section>
  )
}

export default Header